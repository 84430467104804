<template>
  <div>
    <el-row class="grid-content bg-purple-dark">
      <el-col :span="20" class="bg-purple-title">{{ $t('order.Orderinformation') }}</el-col>
    </el-row>
    <el-form ref="orderForm" :model="orderForm" label-width="140px">
      <div class="el-lt" style="width:100%;">
        <el-row :span="24">
          <el-col :span="6">
            <el-form-item :class="$i18n.locale" :label="$t('order.fbaPlatform')">
              <el-input v-model="orderForm.platform" :placeholder="$t('page.inputPlaceholder')" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :class="$i18n.locale" :label="$t('title.ordernumber')">
              <el-input v-model="orderForm.orderNumber" :placeholder="$t('page.inputPlaceholder')" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :class="$i18n.locale" :label="$t('order.Sourcenumber')">
              <el-input v-model="orderForm.purchaseOrderNumber" :placeholder="$t('page.inputPlaceholder')" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('order.types')" prop="orderType">
              <el-select v-model="orderForm.orderType" :placeholder="$t('page.selectPlaceholder')" disabled>
                <el-option v-for="item in _getAllCommodityDict('SELF_ORDER_TYPE')" :key="item.val" :label="item.label" :value="item.val" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :class="$i18n.locale" :label="$t('order.SystemID')">
              <el-input v-model="orderForm.mwOrderCode" :placeholder="$t('page.inputPlaceholder')" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('order.Orderstatus')">
              <el-select v-model="orderForm.orderStatus" :placeholder="$t('page.inputPlaceholder')" disabled style="width:100%">
                <el-option :label="$t('order.Cancel')" value="0" />
                <el-option :label="$t('order.Awaitingreview')" value="1" />
                <el-option :label="$t('order.Systemreviewed')" value="2" />
                <el-option :label="$t('order.Shipped')" value="3" />
              </el-select>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="6">
            <el-form-item label="平台类型">
              <el-input v-model="orderForm.createByName" :placeholder="$t('page.inputPlaceholder')" disabled />
            </el-form-item>
          </el-col> -->
          <el-col :span="6">
            <el-form-item :label="$t('tips.Salespath')">
              <el-input v-model="orderForm.salesChannel" :placeholder="$t('page.inputPlaceholder')" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('order.Currency')">
              <el-input v-model="orderForm.currency" :placeholder="$t('page.inputPlaceholder')" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('order.Orderamount')">
              <el-input v-model="orderForm.orderTotal" :placeholder="$t('page.inputPlaceholder')" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('order.Timezone')">
              <el-input v-model="orderForm.timeZone" :placeholder="$t('page.inputPlaceholder')" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('order.Purchasetime')">
              <el-input v-model="orderForm.orderCreateMarketDate" :placeholder="$t('page.inputPlaceholder')" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('order.Updatetime')">
              <el-input v-model="orderForm.updateTime" :placeholder="$t('page.inputPlaceholder')" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('order.Fulfillmentmethod')">
              <el-select v-model="orderForm.fulfillmentChannel" :placeholder="$t('page.inputPlaceholder')" disabled style="width:100%">
                <el-option :label="$t('order.Platformfulfilled')" value="0" />
                <el-option :label="$t('order.Sellerfulfilled')" value="1" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="ShipServiceLevel">
              <el-input v-model="orderForm.shipServiceLevel" :placeholder="$t('page.inputPlaceholder')" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="BuyerID">
              <el-input v-model="orderForm.buyerId" :placeholder="$t('page.inputPlaceholder')" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('order.Buyername')">
              <el-input v-model="orderForm.buyerName" :placeholder="$t('page.inputPlaceholder')" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('order.Buyeremail')">
              <el-input v-model="orderForm.buyerEmail" :placeholder="$t('page.inputPlaceholder')" disabled />
            </el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>

    <el-row class="grid-content bg-purple-dark">
      <el-col :span="20" class="bg-purple-title">{{ $t('order.Receiptinformation') }}</el-col>
    </el-row>
    <el-form ref="orderForm" :model="orderForm" label-width="130px">
      <div class="el-lt" style="width:100%;">
        <el-row :span="24">
          <el-col :span="6">
            <el-form-item :label="$t('order.Receipt')">
              <el-input v-model="orderForm.receiveName" :placeholder="$t('page.inputPlaceholder')" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('order.reAddress')">
              <el-input v-model="orderForm.addressLine1" :placeholder="$t('page.inputPlaceholder')" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('order.reAddress')">
              <el-input v-model="orderForm.addressLine2" :placeholder="$t('page.inputPlaceholder')" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('order.Statesd')">
              <el-input v-model="orderForm.stateRegion" :placeholder="$t('page.inputPlaceholder')" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('title.city')">
              <el-input v-model="orderForm.city" :placeholder="$t('page.inputPlaceholder')" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('order.Zipcode')">
              <el-input v-model="orderForm.postalCode" :placeholder="$t('page.inputPlaceholder')" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('order.Country')">
              <el-input v-model="orderForm.countryCode" :placeholder="$t('page.inputPlaceholder')" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('title.phone')">
              <el-input v-model="orderForm.receivePhone" :placeholder="$t('page.inputPlaceholder')" disabled />
            </el-form-item>
          </el-col>

        </el-row>
      </div>
    </el-form>
    <el-row class="grid-content bg-purple-dark">
      <el-col :span="20" class="bg-purple-title">{{ $t('order.other') }}</el-col>
    </el-row>
    <el-form ref="orderForm" :model="orderForm" label-width="130px">
      <el-row>
        <el-col :span="6">
          <el-form-item :label="$t('order.ReturnRefund')">
            <el-input v-model="orderForm.type" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <!-- <el-col :span="6">
          <el-form-item label="是否测评">
            <el-input v-model="orderForm.receiveTime" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="Review模板">
            <el-input v-model="orderForm.completeTime" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="Review邀请时间">
            <el-input v-model="orderForm.sendSiteCode" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="Feedback模板">
            <el-input v-model="orderForm.sendSiteCode" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="Feedback邀请时间">
            <el-input v-model="orderForm.sendSiteCode" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col> -->
      </el-row>
    </el-form>
    <el-row style="width:100%;margin:20px 0;">
      <el-col
        style="width:100%;padding: 15px 0px; white-space: nowrap;"
      >
        <el-tabs v-model="activeName">
          <el-tab-pane :label="$t('order.Productinformation')" name="first">
            <el-table
              :data="goodsData"
              :header-cell-style="{background:'#fafafa'}"
              align="left"
              class="mb-3"
              max-height="600px"
            >
              <el-table-column
                :label="$t('page.No')"
                align="center"
                type="index"
                width="70"
              >
                <template slot-scope="scope">
                  {{ scope.$index + 1 }}
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                label="MSKU"
                prop="originalFbmSku"
              />
              <el-table-column
                align="center"
                label="Asin/itemID"
                prop="asin"
              >
                <template slot-scope="scope">
                  {{ scope.row.asin ? scope.row.asin : scope.row.itemId }}
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                label="SKU"
                prop="sku"
              />
              <el-table-column
                :label="$t('title.Quantity')"
                align="center"
                prop="itemQuantity"
              />
              <el-table-column
                :label="$t('order.Totalamount')"
                align="center"
                prop="itemPrice"
              />
              <el-table-column
                align="center"
                label="Status"
                prop="itemStatus"
              >
                <template slot-scope="scope">
                  <p v-if="scope.row.itemStatus == 0">{{ $t('title.Canceled') }}</p>
                  <p v-else-if="scope.row.itemStatus == 1">{{ $t('order.Awaitingreview') }}</p>
                  <p v-if="scope.row.itemStatus == 2">{{ $t('order.Systemreviewed') }}</p>
                  <p v-else-if="scope.row.itemStatus == 3">{{ $t('order.Shipped') }}</p>
                  <p v-else>{{ scope.row.itemStatus }}</p>
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('order.Trackingnumber')"
                align="center"
                prop="trackingNumber"
              />
            </el-table>
          </el-tab-pane>
          <el-tab-pane :label="$t('order.Chargedatails')" name="second">
            <el-table
              :data="costData"
              :header-cell-style="{background:'#fafafa'}"
              align="left"
              class="mb-3"
              max-height="600px"
            >
              <el-table-column
                :label="$t('page.No')"
                align="center"
                type="index"
                width="70"
              >
                <template slot-scope="scope">
                  {{ scope.$index + 1 }}
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('title.time')"
                align="center"
                prop="createTime"
              />
              <el-table-column
                align="center"
                label="SKU"
                prop="originSku"
              />
              <el-table-column
                :label="$t('title.Quantity')"
                align="center"
                prop="itemQuantity"
              />
              <el-table-column
                :label="$t('order.Type')"
                align="center"
                prop="feeType"
              />
              <el-table-column
                :label="$t('order.SubtotalRMB')"
                align="center"
                prop="originalValue"
              />
              <el-table-column
                :label="$t('order.ExchangerateUSD')"
                align="center"
                prop="usExchangeValue"
              />
              <el-table-column
                :label="$t('order.Subtotal')"
                align="center"
                prop="usValue"
              />
              <el-table-column
                :label="$t('order.Exchangerate')"
                align="center"
                prop="rmbExchangeValue"
              />
              <el-table-column
                :label="$t('order.RMB')"
                align="center"
                prop="rmbValue"
              />
            </el-table>
          </el-tab-pane>
          <el-tab-pane :label="$t('order.Log')" name="third">
            <el-table
              :data="logData"
              :header-cell-style="{background:'#fafafa'}"
              align="left"
              class="mb-3"
              max-height="600px"
            >
              <el-table-column
                :label="$t('page.No')"
                align="center"
                type="index"
                width="70"
              >
                <template slot-scope="scope">
                  {{ scope.$index + 1 }}
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('order.types')"
                align="center"
                prop="logType"
              >
                <template slot-scope="scope">
                  <p v-if="scope.row.logType == 1">{{ $t('page.status') }}</p>
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('order.Original')"
                align="center"
                prop="beforeStatus"
              />
              <el-table-column
                :label="$t('order.Changed')"
                align="center"
                prop="afterStatus"
              />
              <el-table-column
                :label="$t('order.OperationTime')"
                align="center"
                prop="utcDate"
              />
              <el-table-column
                :label="$t('title.operator')"
                align="center"
                prop="updateByName"
              />
            </el-table>
          </el-tab-pane>
        </el-tabs>
        <!--        <span class="row-bg" style="display: inline-block;padding:2px 10px; margin-left:10px;cursor:pointer" @click="selectStatus('商品信息')"> {{ '商品信息' }}  </span>-->
        <!--        <span class="row-bg" style="display: inline-block;padding:2px 10px; margin-left:10px;cursor:pointer" @click="selectStatus('费用明细')"> {{ '费用明细' }}  </span>-->
        <!--        <span class="row-bg" style="display: inline-block;padding:2px 10px; margin-left:10px;cursor:pointer" @click="selectStatus('日志')"> {{ '日志' }}  </span>-->
      </el-col>
    </el-row>

    <!-- 日志 -->
    <!-- <el-table
      v-if="tableName === '商品信息'"
      :header-cell-style="{background:'#fafafa'}"
      :data="goodsData"
      class="mb-3"
      max-height="600px"
      align="left"
    >
      <el-table-column
        :label="$t('page.No')"
        type="index"
        width="70"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        prop="originalFbmSku"
        label="MSKU"
        align="center"
      />
      <el-table-column
        prop="asin"
        label="Asin"
        align="center"
      />
      <el-table-column
        prop="sku"
        label="SKU"
        align="center"
      />
      <el-table-column
        prop="itemQuantity"
        label="数量"
        align="center"
      />
      <el-table-column
        prop="itemPrice"
        label="商品总价"
        align="center"
      />
      <el-table-column
        prop="itemStatus"
        label="Status"
        align="center"
      />
      <el-table-column
        prop="trackingNumber"
        label="跟踪单号"
        align="center"
      />
    </el-table>
    <el-table
      v-if="tableName === '费用明细'"
      :header-cell-style="{background:'#fafafa'}"
      :data="costData"
      class="mb-3"
      max-height="600px"
      align="left"
    >
      <el-table-column
        :label="$t('page.No')"
        type="index"
        width="70"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        prop="createTime"
        label="结算时间"
        align="center"
      />
      <el-table-column
        prop="originSku"
        label="SKU"
        align="center"
      />
      <el-table-column
        prop="itemQuantity"
        label="数量"
        align="center"
      />
      <el-table-column
        prop="feeType"
        label="费用类型"
        align="center"
      />
      <el-table-column
        prop="originalValue"
        label="金额（本币）"
        align="center"
      />
      <el-table-column
        prop="usExchangeValue"
        label="汇率(本币转美金)"
        align="center"
      />
      <el-table-column
        prop="usValue"
        label="金额（美金）"
        align="center"
      />
      <el-table-column
        prop="rmbExchangeValue"
        label="汇率（美金转人民币）"
        align="center"
      />
      <el-table-column
        prop="rmbValue"
        label="人民币"
        align="center"
      />
      <el-table-column
        prop="updateTime"
        label="更新时间"
        align="center"
      />
    </el-table> -->
    <!-- 日志 -->
    <!-- <el-table
      v-if="tableName === '日志'"
      :header-cell-style="{background:'#fafafa'}"
      class="mb-3"
      :data="logData"
      max-height="600px"
      align="left"
    >
      <el-table-column
        :label="$t('page.No')"
        type="index"
        width="70"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        prop="logType"
        label="类型"
        align="center"
      />
      <el-table-column
        prop="beforeStatus"
        label="原始内容"
        align="center"
      />
      <el-table-column
        prop="afterStatus"
        label="调整内容"
        align="center"
      />
      <el-table-column
        prop="utcDate"
        label="操作时间"
        align="center"
      />
      <el-table-column
        prop="updateByName"
        label="操作人"
        align="center"
      />
    </el-table> -->

  </div>
</template>

<script>
import mixin from '@/mixin/oms-mixin'
import { orderInfoDetail } from '@/api/omsorder'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'

export default {
  mixins: [mixin, commodityInfoDict],
  data() {
    return {
      orderForm: {},
      activeName: 'first',
      tableName: '商品信息',
      goodsData: [],
      costData: [],
      logData: [],
      datasObj: {}

    }
  },
  mounted() {
    this._orderInfoDetail()
  },

  methods: {
    // 通过id 查询对应数据
    async _orderInfoDetail() {
      const { id, orderCreateUtcDate } = this.$route.query
      if (id) {
        const { data: datas } = await orderInfoDetail({ id, createUtcTime: orderCreateUtcDate })
        this.datasObj = datas
        const { salesChannel, stateRegion, shipServiceLevel, updateTime, orderCreateMarketDate, timeZone, orderTotal, currency, fulfillmentChannel, buyerId, buyerName, buyerEmail, addressLine2, addressLine1, countryCode, city, postalCode, receivePhone, platform, orderNumber, purchaseOrderNumber, mwOrderCode, orderStatus, receiveName } = datas
        this.orderForm = { stateRegion, salesChannel, shipServiceLevel, updateTime, orderCreateMarketDate, timeZone, orderTotal, currency, fulfillmentChannel: fulfillmentChannel ? String(fulfillmentChannel) : '', buyerId, buyerName, buyerEmail, addressLine2, addressLine1, countryCode, city, postalCode, receivePhone, platform, orderNumber, purchaseOrderNumber, mwOrderCode, orderStatus, receiveName }
        this.orderForm.platform = datas.platform + ' _ ' + datas.site + ' _ ' + datas.merchant
        this.goodsData = datas.items
        this.costData = datas.itemFees
        this.logData = datas.logs
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  /deep/ .el-upload--picture-card {
    width: 75px;
    height: 75px;
    cursor: pointer;
    line-height: 75px;
  }

  .hide .el-upload--picture-card {
    display: none;
  }
</style>
